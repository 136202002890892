<template>
	<Dialog :dialog="dialog" :dialogWidth="dialogWidth" dense>
		<template v-slot:title> Select Asset </template>
		<template v-slot:body>
			<v-container fluid class="px-4">
				<template>
					<v-row>
						<v-col md="12">
							<v-layout>
								<v-flex md11>
									<TextInput
										:disabled="dataLoading"
										:loading="dataLoading"
										v-model="search"
										hide-details
										placeholder="Search..."
									></TextInput>
								</v-flex>
								<v-flex v-if="search">
									<v-btn
										:disabled="dataLoading"
										v-on:click="
											search = null;
											getAssets();
										"
										tile
										depressed
										class="mt-3"
										color="red lighten-1 white--text"
									>
										<v-icon>mdi-close</v-icon> Cancel</v-btn
									>
								</v-flex>
								<v-flex>
									<v-btn
										:disabled="dataLoading"
										v-on:click="getAssets()"
										tile
										depressed
										class="mt-3"
										color="blue darken-4 white--text"
									>
										<v-icon>mdi-magnify</v-icon> Search</v-btn
									>
								</v-flex>
							</v-layout>
						</v-col>
						<v-col md="12">
							<div>
								<table width="100%">
									<template v-if="dataLoading">
										<tr>
											<td colspan="2" align="center" height="93.75px" class="border-light-grey">
												<div class="py-2">
													<v-progress-circular indeterminate color="blue darken-4"></v-progress-circular>
													<p class="mb-0 mt-2 font-level-3 bold-600">Please wait...</p>
												</div>
											</td>
										</tr>
									</template>
									<template v-else>
										<template v-if="assetList.length">
											<tr
												v-on:click="selectAsset(data)"
												v-for="(data, index) in assetList"
												:key="index"
												class="cursor-pointer border-light-grey"
											>
												<td width="20%" align="center">
													<ImageTemplate width="80" circle :src="data.image"></ImageTemplate>
												</td>
												<td width="80%">
													<div class="py-2">
														<Chip color="blue darken-4" :text="data.barcode"></Chip>
														<p class="mt-2 mb-1 font-level-3" v-if="data.id_number">
															Asset ID <span class="bold-500">{{ data.id_number }}</span>
														</p>
														<p class="mb-1 font-level-3" v-if="data.name">
															Asset Name <span class="bold-500">{{ data.name }}</span>
														</p>
													</div>
												</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td colspan="2" align="center" height="93.75px" class="border-light-grey">
													<div class="py-2">
														<p class="mb-0 font-level-3 bold-600">Oops... Nothing Found</p>
													</div>
												</td>
											</tr>
										</template>
									</template>
								</table>
							</div>
							<br />
						</v-col>
					</v-row>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="blue darken-4 white--text"
				tile
				:disabled="dataLoading"
				depressed
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import ApiService from "@/core/services/api.service";
import { toSafeInteger } from "lodash";

export default {
	data() {
		return {
			dataLoading: false,
			assetList: [],
			search: null,
			timeoutLimit: 0,
			timeout: null,
		};
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Dialog,
		Chip,
		TextInput,
		ImageTemplate,
	},
	watch: {
		dialog(param) {
			if (param) {
				this.getAssets();
			}
		},
	},
	methods: {
		getAssets() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.dataLoading = true;
				_this.assetList = [];
				ApiService.query("asset-list", { search: _this.search })
					.then(({ data }) => {
						_this.assetList = data;
					})
					.catch((error) => {
						_this.$emit("error", error);
						_this.logError(error);
					})
					.finally(() => {
						_this.dataLoading = false;
					});
			}, _this.timeoutLimit);
		},
		selectAsset({ uuid }) {
			this.$emit("select", uuid);
			this.$emit("close", true);
		},
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
